<template>
  <b-container>
    <b-form @submit.stop.prevent>
      <b-row class="justify-content-center text-center mb-3">
        <b-col cols="11">
          <b-form-row>
            <label for="searchText">{{ $t('acc.search') }}</label>
          </b-form-row>
          <b-form-row>
            <b-input-group id="searchInput">
              <b-form-input type="text" id="searchTextRest" :placeHolder="$t('tab.n')" v-model="iname" autofocus @keyup.enter.native="onSearch" />
              <b-form-input type="text" id="searchTextCity" :placeHolder="$t('tab.c')" v-model="icity" @keyup.enter.native="onSearch" />

              <template #append>
                <b-dropdown variant="primary" id="searchDd" ref="searchGeoDd" right lazy menu-class="pb-0"
                    v-if="geoLocationAvailable && !geo.err" @show="mapSearch" @hide="mapClose" no-caret>
                  <template #button-content>
                    <Icon type="map" hasNoText />
                  </template>
                  <div v-if="geo.gettingLocation" class="text-center px-2 px-sm-3 mapwidth">
                    <b-spinner variant="primary" />
                  </div>
                  <b-container v-if="!geo.gettingLocation" class="px-2 px-sm-3 mapwidth">
                    <b-form-group id="favMapContent" :label="$t('acc.fmap')" label-for="searchMap">
                      <MapCore :center="geoCenter" :items="items" :btnText="$t('btn.add')" @clicked="onMapSelect" @refreshed="onMapRefresh" />
                    </b-form-group>
                  </b-container>
                </b-dropdown>
                <b-button type="button" variant="primary" ref="btn" @click="onSearch">
                  <Icon type="sea" /> {{ $t('btn.sea') }}
                </b-button>
              </template>
            </b-input-group>
          </b-form-row>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="items.length">
        <b-table striped hover :fixed="isXs" :items="items" :fields="fields" class="mb-2 mb-sm-3">
          <template #cell(uuid4)="data">
            <b-checkbox button @change.prevent :ref="'cb' + items.indexOf(data.item).toString()" class="px-1"
                @click.native.prevent="onSelect(data.item)">
              <Icon type="add" hasNoText />
            </b-checkbox>
          </template>
        </b-table>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
// autofocus on searchText may break screenreader
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import MapCore from '@/components/MapCore';

export default {
  data() {
    return {
      name: null,
      city: null,
      geo: {
        center: null,
        gettingLocation: false,
        err: false
      },
      items: [],
    }
  },
  computed: {
    fields() {
      return [
        { key: 'name', label: this.$t('tab.l'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 60%;', sortable: true },
        { key: 'city', label: this.$t('tab.c'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 40%;', sortable: true },
        { key: 'uuid4', label: (this.isXs?'':this.$t('btn.add')), class: 'px-1 text-center', thStyle: 'width: 40px;', sortable: false }
      ];
    },
    iname: {
      get() {
        return this.name;
      },
      set(v) {
        if (v == null || v == '') {
          this.name = null;
        } else {
          this.name = v;
        }
      }
    },
    icity: {
      get() {
        return this.city;
      },
      set(v) {
        if (v == null || v == '') {
          this.city = null;
        } else {
          this.city = v;
        }
      }
    },
    geoLocationAvailable() {
      return ('geolocation' in navigator);
    },
    geoCenter() {
      return this.geo.center;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  created() {
    this.geo.center = null;
    this.geo.gettingLocation = true;
    if (!this.geoLocationAvailable) {
      this.geo.err = true;
    }
  },
  methods: {
    onMapSelect(id) {
      this.name = null
      this.city = null;
      this.$refs.searchGeoDd.hide();
      this.onSelect(this.items[id], false);
    },
    onMapRefresh(latlng) {
      this.geo.center = latlng;
      this.vicinityFetch(this.geo.center);
    },
    mapClose() {
      this.geo.gettingLocation = false;
      this.geo.center = null;
    },
    async mapSearch() {
      if (this.geo.center === null) {
        this.geo.gettingLocation = true;
        // event.preventDefault();
        navigator.geolocation.getCurrentPosition(async pos => {
          this.geo.gettingLocation = false;
          this.geo.center = [pos.coords.latitude, pos.coords.longitude];
          try {
            await this.vicinityFetch(this.geo.center);
            // this.$refs.searchGeoDd.show();
          } catch(_) {
            this.$refs.searchGeoDd.hide();
            this.mapClose();
          }
        }, err => {
          this.geo.err = true;
          this.$refs.searchGeoDd.hide();
          this.mapClose();
          this.$store.commit('showWarn', err.message);
        });
      }
    },
    onSearch() {
      this.items = [];
      let qArray = [];
      if (this.name !== null && this.name !== '') {
        qArray.push(['name', 's', this.name]);
      }
      if (this.city !== null && this.city !== '') {
        qArray.push(['city', 's', this.city]);
      }
      if (qArray == []) {
          this.$emit('showWarn', 'Name or city required.');
          return;
      }
      let qObj = Query.setFilter({ p: '10' }, qArray);
      REST.get('/restaurants', { params: qObj }
          ).then(resp => {
            this.items = resp.d.data;
          }).catch(e => {
            if (e.code != 404) {
              this.$emit('showWarn', e.message);
            } else {
              this.name = null
              this.city = null;
            }
          })
    },
    async vicinityFetch(arr) {
      if (arr && arr.length === 2) {
        REST.get('/restaurants/20000/' + arr[0] + '/' + arr[1]
          ).then(resp => {
            this.items = resp.d.data;
          }).catch(e => {
            this.$emit('showWarn', e.message);
          })
      }
    },
    onSelect(item) {
      this.$emit('add', item.uuid4);
      const x = this.items.indexOf(item);
      this.items.splice(x, 1);
    }
  },
  components: {
    MapCore,
    Icon,
  }
}
</script>

<style scoped>
.mapwidth {
  width:97vw;
}
@media screen and (min-width: 800px) {
  .mapwidth {
    min-width:600px;
    width:600px;
  }
}
</style>