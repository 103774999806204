<template>
  <!-- <div v-if="isLoading" class="d-flex my-3 justify-content-center text-center">
    <b-spinner :label="$t('dial.load')" style="width: 3rem; height: 3rem;"/>
  </div>
  <div v-else-if="favItems.length">  -->
  <div>
    <b-table striped hover sort-icon-right :fixed="isXs"
        class="mb-2 mb-sm-3"
        :items="favItems" :fields="favFields"
        :sort-by.sync="favSortBy" :sort-desc.sync="favSortDesc" no-local-sorting @sort-changed="sortChanged">
      <template #cell(uuid4)="data">
        <div v-if="btnShow">
          <b-dropdown v-if="isXs" text="" right>
            <b-dropdown-item :variant="btnVariant" :id="btnIdPrefix+favItems.indexOf(data.item)"
                @click.native="btnClick(data.item)" :to="btnTo">
              <slot name="buttons" />
            </b-dropdown-item>
          </b-dropdown>
          <b-button v-else type="button" :variant="btnVariant" :id="btnIdPrefix+favItems.indexOf(data.item)"
              @click="btnClick(data.item)" :to="btnTo">
            <slot name="buttons" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row fluid>
      <b-col v-if="showPagination" class="d-flex align-items-end">
        <Pagination ref="pagi" @click="changePage" />
      </b-col>
      <b-col>
        <slot name="nextToPagi" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import Pagination from '@/components/Pagination';

export default {
  data() {
    return {
      favItems: [],
      favSortBy: 'customerName',
      favSortDesc: false,
      isLoading: false,
    }
  },
  created() {
    // values would need to be moved to store to survive "F5"
    [this.favSortDesc, this.favSortBy, ] = Query.fetchSort(this.query, this.favSortDesc, this.favSortBy);
    Query.setSort(this.query, this.favSortDesc, this.favSortBy, 'uuid4');
    this.update();
  },
  computed: {
    favFields() {
      return [
        { key: 'customerName', label: this.$t('tab.l'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 50%;', sortable: true },
        { key: 'customerCity', label: this.$t('tab.c'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 50%;', sortable: true },
        { key: 'uuid4', label: this.isXs?'':this.$t('tab.a'), class: 'pl-2 pl-sm-3 pr-0', thStyle: 'width: 35px;' },
      ];
    },
    uiSett() {
      return this.$store.getters.settings;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  methods: {
    sortChanged(ctx) {
      if (ctx.sortBy == '') { return; }
      [this.favSortDesc, this.favSortBy, ] = Query.fetchSort(this.query, ctx.sortDesc, ctx.sortBy);
      Query.setSort(this.query, ctx.sortDesc, ctx.sortBy, 'uuid4');
      if (this.showPagination) {
        this.$refs.pagi.reset();
      }
      Query.setPagi(this.query, null, this.uiSett.visiblePerPage);
      this.update();
    },
    changePage(nextString) {
      Query.setPagi(this.query, nextString, this.uiSett.visiblePerPage);
      this.update();
    },
    update() {
      this.isLoading = true;
      REST.get('/users/' + this.$store.getters.uuid + '/favorites', this.getFormattedQuery()
          ).then(resp => {
            this.favItems = resp.d.data; // before isLoading = false to avoid flickering
            this.isLoading = false; // needed for pagination update (ref not available if v-if=false)
            if (this.showPagination) {
              this.$nextTick(() => { // refs not yet existing (rendered) without nextTick
                if (resp.d.links.next !== '') {
                  this.$refs.pagi.update(resp.d.links.next);
                }
                this.$emit('loaded', { hasContent: true, hasMore: this.$refs.pagi.hasMore() });
              });
            }
          }).catch(e => {
            // catch 404 warning: user may not have added favs yet
            if (e.code != 404) {
              this.$store.commit('showWarn',e.message);
            }
            this.favItems = [];
            // if (this.showPagination) {
            //   this.$nextTick(() => {
                this.$emit('loaded', { hasContent: false, hasMore: false });
            //   });this.$refs.pagi.hasMore()
            // }
          }).finally(() => {
            this.isLoading = false;
          })
    },
    getFormattedQuery() {
      // generate "current" query object, based on supplied template and params
      let newQuery = {...this.query};
      return { params: Query.replaceMagic(newQuery) };
    },
  },
  props: {
    showPagination: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    btnShow: {
      type: Boolean,
      default: true,
    },
    btnIsDd: {
      type: Boolean,
      default: false,
    },
    btnTo: {
      type: String,
      default: null,
    },
    btnClick: {
      type: Function,
      default() {},
    },
    btnVariant: {
      type: String,
      default: 'primary',
    },
    btnIdPrefix: {
      type: String,
      default: 'fav',
    },
  },
  components: {
    Pagination,
  },
}
</script>
