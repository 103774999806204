<template>
  <div v-if="isUser">
    <b-row fluid class="mx-0 mb-2 mb-sm-3 justify-content-between">
      <h4 class="d-flex align-items-center mb-0">
        {{ $t('acc.hfm') }}
      </h4>
      <b-button type="button" variant="primary" class="mx-0" id="accFavoAdd" v-b-modal.accFavoAddModal>
        <Icon type="add" /> {{ $t('btn.add') }}
      </b-button>
    </b-row>
    <UserFavoritesTable ref="table" :query="{p: ``+uiSett.visiblePerPage}" showPagination
        :btnShow="true" btnIdPrefix="fav" btnVariant="primary" :btnClick="onDelete">
      <template #buttons>
        <span class="text-nowrap"><Icon type="del" /> {{ $t('btn.del') }}</span>
      </template>
    </UserFavoritesTable>

    <b-modal id="accFavoAddModal" ref="modal" :title="$t('acc.fadd')" size="lg" body-class="px-2 px-sm-3"
        lazy ok-only @hide="onAddHide">
      <template #modal-ok>
        <Icon type="x" /> {{ $t('btn.x') }}
      </template>
      <UserFavoritesSearch id="accFavoAddForm" class="mt-1" @warn="onWarn" @add="onAdd" />
    </b-modal>
    <ModalYesNoGeneric id="accFavoDelConf" :title="$t('acc.fdel')"
        :btnTextReset="$t('btn.a')" btnIconReset="a"
        :btnTextSubmit="$t('btn.del')" btnIconSubmit="del" btnVariantSubmit="secondary"
        @reset="item=null" @submit="onConfirmation(item)" />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';
import UserFavoritesTable from '@/components/account/UserFavoritesTable';
import UserFavoritesSearch from '@/components/account/UserFavoritesSearch';
import ModalYesNoGeneric from '@/components/ModalYesNoGeneric';

export default {
  data() {
    return {
      additions: false,
      item: null,
    }
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    uiSett() {
      return this.$store.getters.settings;
    },
  },
  methods: {
    onDelete(item) {
      this.item = item;
      this.$bvModal.show('accFavoDelConf');
    },
    onConfirmation() {
      REST.delete('/favorites/' + this.item.uuid4
          ).then(resp => {
            this.$refs.table.update(); // maybe better sync via store
            this.$store.commit('showSuccess',resp.d.message);
          }).catch(e => {
            this.$store.commit('showWarn',e.message);
          })
      this.item = null;
    },
    onAdd(uuid4) {
      REST.post('/favorites', 
            { customerUuid4: uuid4,
              userUuid4: this.$store.getters.uuid }
          ).then(() => {
            this.additions = true;
          }).catch(e => {
            this.$refs.modal.hide();
            // catch 404 warning: user may not have added favs yet
            if (e.code != 404) {
              this.$store.commit('showWarn',e.message);
            }
          })
    },
    onAddHide() {
      if (this.additions) {
        this.$refs.table.update(); // maybe better sync via store
        this.$store.commit('showSuccess','Favorites added.');
      }
    },
    onWarn(text) {
      this.$refs.modal.hide();
      this.$store.commit('showWarn',text);
    },
  },
  components: {
    UserFavoritesTable,
    UserFavoritesSearch,
    ModalYesNoGeneric,
    Icon,
  },
}
</script>
